
import React,  { useState } from "react";
import mk from "./img/mk.svg";
import { Transition } from "@headlessui/react";

export const Navbar2 = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div className= 'absolute top-0 w-screen font-inter'>
        <nav className="w-screen sticky top-0' bg-gradient-to-r from-custom-blue to-[#8E87E9]">
          <div className="max-w-7xl mx-auto px-4 sm:px-7 lg:px-7">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-8 w-8"
                    src={mk}
                    alt="Workflow"
                  />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    <a
                      href="#"
                      className=" hover:bg-gray-700/70 text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                      About
                    </a>
  
                    <a
                      href="#projects"
                      className="text-white hover:bg-gray-700/70 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Projects
                    </a>
  
                    <a
                      href="#skills"
                      className="text-white hover:bg-gray-700/70 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Skills
                    </a>
  
                    <a
                      href="#school"
                      className="text-white hover:bg-gray-700/70 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                      School Portfolio
                    </a>
  
                    
                  </div>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
  
          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="md:hidden" id="mobile-menu">
                <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  <a
                    href="#"
                    className="hover:bg-gray-700/70 text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    About
                  </a>
  
                  <a
                    href="#"
                    className="text-gray-300 hover:bg-gray-700/70 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Skills
                  </a>
  
                  <a
                    href="#"
                    className="text-gray-300 hover:bg-gray-700/70 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Projects
                  </a>
  
                  <a
                    href="#"
                    className="text-gray-300 hover:bg-gray-700/70 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Contact
                  </a>
  
                  
                </div>
              </div>
            )}
          </Transition>
        </nav>
  
      
      </div>
    );
    
}